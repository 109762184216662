import RequireAuth from '../../components/RequireAuth'
import { FUNCTIONALITIES } from '../../helpers'
import { useMemo } from 'react'
import NavButton from './NavButton'
import { withProgram, WithProgramProps } from '../../contexts/ProgramContext'
import BackButton from '../../components/BackButton'
import { CogIcon } from '../../components/Icons/Cog'
import { AudienceIcon } from '../../components/Icons/Audience'
import { StoreIcon } from '../../components/Icons/Store'
import { OfferIcon } from '../../components/Icons/Offer'
import { withSettings, WithSettingsProps } from '../../contexts/SettingsContext'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ToolIcon } from '../../components/Icons/Tool'

type ProgramMenuProps = {
    showNav: boolean
} & WithProgramProps &
    WithSettingsProps

function ProgramMenu({ currentProgram, setCurrentProgram, showNav, settings }: ProgramMenuProps) {
    const { t } = useTranslation('common')
    const location = useLocation()
    const navigate = useNavigate()
    const tableauToken = localStorage.getItem('tableau_token')

    const classes = useMemo(
        () =>
            'flex flex-col relative md:h-auto md:flex-1 transition-all overflow-auto mb-8' +
            (showNav ? 'h-auto' : 'h-0'),
        [showNav]
    )

    let analyticsMenu = (settings as any)?.analyticsMenu as any[]
    if (analyticsMenu && !currentProgram.hasCashback) {
        analyticsMenu = analyticsMenu.filter((m) => m.menuTranslationKey !== 'analytics.rewards')
    }

    const onBack = () => {
        setCurrentProgram(null)
        navigate('/')
    }

    return (
        <>
            <div className="lg:mr-3 flex flex-col mb-5">
                <div className="flex justify-start mb-6">
                    <BackButton label="Menu" onClick={onBack} />
                </div>
                <div className="bg-primary text-white px-3 py-2 overflow-hidden font-bold text-sm flex flex-col justify-center truncate max-w-full">
                    <span className="truncate">{currentProgram!.programName || 'N/A'}</span>
                    <span className="text-xs font-normal truncate">{currentProgram!.name || 'N/A'}</span>
                </div>
            </div>

            <div className={classes}>
                <div className="mr-3 flex-1 flex flex-col gap-y-2">
                    <RequireAuth functionality={FUNCTIONALITIES.AUDIENCE}>
                        <NavButton
                            url={'/programs/' + currentProgram!.id + '/audience'}
                            data-cy="menu_program_audience_button"
                        >
                            <AudienceIcon />
                            {t('menu.user.audience')}
                        </NavButton>
                    </RequireAuth>

                    <RequireAuth functionality={FUNCTIONALITIES.STORE}>
                        <NavButton url={'/programs/' + currentProgram!.id + '/stores'} data-cy="menu_stores_button">
                            <StoreIcon />
                            {t('menu.user.stores')}
                        </NavButton>
                    </RequireAuth>

                    {/*Only white labels has offers. Other programs handle offers themselves*/}
                    {!currentProgram!.hasExternalFront && (
                        <RequireAuth functionality={FUNCTIONALITIES.OFFER}>
                            <NavButton url={'/programs/' + currentProgram!.id + '/offers'} data-cy="menu_offers_button">
                                <OfferIcon />
                                {t('menu.user.offers')}
                            </NavButton>
                        </RequireAuth>
                    )}

                    {analyticsMenu && tableauToken ? (
                        <>
                            <NavButton
                                url={`/programs/${currentProgram!.id}/analytics/${analyticsMenu[0].menuTranslationKey}`}
                                active={location.pathname.includes('/analytics/')}
                            >
                                <AudienceIcon />
                                {t('navigation.analytics')}
                            </NavButton>
                            {location.pathname.includes('/analytics/') && analyticsMenu && (
                                <>
                                    {analyticsMenu.map((item) => {
                                        return (
                                            <NavLink
                                                to={`/programs/${currentProgram!.id}/analytics/${
                                                    item.menuTranslationKey
                                                }`}
                                                key={item.menuTranslationKey}
                                            >
                                                {({ isActive }) => (
                                                    <div className={'block ml-8 ' + (isActive ? '' : 'text-black')}>
                                                        {t(item.menuTranslationKey)}
                                                    </div>
                                                )}
                                            </NavLink>
                                        )
                                    })}
                                </>
                            )}
                        </>
                    ) : null}

                    <RequireAuth internalUser>
                        <NavButton
                            url={'/programs/' + currentProgram!.id + '/configuration'}
                            data-cy="menu_configuration_button"
                        >
                            <CogIcon />
                            {t('menu.user.configuration')}
                        </NavButton>
                    </RequireAuth>

                    <RequireAuth internalUser functionality={FUNCTIONALITIES.TOOLS}>
                        <NavButton url={'/programs/' + currentProgram!.id + '/tools'} data-cy="menu_tools_button">
                            <ToolIcon />
                            {t('menu.user.tool')}
                        </NavButton>
                    </RequireAuth>
                </div>
            </div>
        </>
    )
}

export default withSettings(withProgram(ProgramMenu))
