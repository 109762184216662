import 'rc-collapse/assets/index.css'
import CheckboxFilter from './shared/CheckboxFilter'
import { CommonFilterProps } from './shared/audience-filters-types'

type RetrievalMethodFilterProps = CommonFilterProps & {}

function RetrievalMethodFilter(props: RetrievalMethodFilterProps) {
    return (
        <>
            <CheckboxFilter {...props} id="dataRetrievalMethod.al" />
            <CheckboxFilter {...props} id="dataRetrievalMethod.cl" />
            <CheckboxFilter {...props} id="dataRetrievalMethod.scan" />
        </>
    )
}

export default RetrievalMethodFilter
