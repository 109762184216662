import React, { forwardRef, ReactNode, useMemo, useState } from 'react'
import ErrorMessage, { ErrorState } from '../components/ErrorMessage'

export type WithErrorManagementProps = {
    handleError: (translationKey: string) => (e: any) => void
}

const ErrorManagementContext = React.createContext<WithErrorManagementProps>({
    handleError: () => () => {},
})

export function ErrorManagementProvider({ children }: { children: ReactNode }) {
    const [serverErrors, setServerErrors] = useState<ErrorState[]>([])
    const handleError = (translationKey: string) => (e: any) => {
        setServerErrors((prev) => [...prev, { key: translationKey, code: e.code }])
    }

    const serverError = useMemo(() => (serverErrors.length ? serverErrors[0] : undefined), [serverErrors])

    return (
        <ErrorManagementContext.Provider
            value={{
                handleError,
            }}
        >
            <ErrorMessage
                translationKey={serverError?.key}
                errorCode={serverError?.code}
                show={!!serverError}
                onClose={() => setServerErrors(([_, ...others]) => others)}
            />

            {children}
        </ErrorManagementContext.Provider>
    )
}

export function withErrorManagement<T extends WithErrorManagementProps = WithErrorManagementProps>(
    Component: React.ComponentType<T>
): (p: any) => any {
    return forwardRef((props: any, ref: any) => (
        <ErrorManagementContext.Consumer>
            {(value) => <Component {...props} {...value} ref={ref} />}
        </ErrorManagementContext.Consumer>
    ))
}

export default ErrorManagementContext
