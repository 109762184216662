import React, { useCallback } from 'react'
import './Input.css'

export enum InputType {
    DATE = 'date',
    DATETIME_LOCAL = 'datetime-local',
    EMAIL = 'email',
    MONTH = 'month',
    NUMBER = 'number',
    PASSWORD = 'password',
    TEL = 'tel',
    TEXT = 'text',
    TIME = 'time',
    URL = 'url',
    WEEK = 'week',
}

type BaseInputProps = {
    className?: string
    error?: Error
    disabled?: boolean
    maxLength?: number
    placeholder?: string
    currency?: boolean
    validateIntegerNumbers?: boolean
    'data-cy'?: string
} & Omit<React.HTMLProps<HTMLInputElement>, 'onChange'>

type StringInputProps = {
    type?: Exclude<InputType, InputType.NUMBER>
    onChange?: (value: string) => void
    value?: string
} & BaseInputProps

type NumberInputProps = {
    type?: InputType.NUMBER
    onChange?: (value: number) => void
    value?: number
} & BaseInputProps

type InputProps = StringInputProps | NumberInputProps

function StandardInput({
    type = InputType.TEXT,
    className = '',
    onChange = undefined,
    value = '',
    placeholder = '',
    currency = false,
    validateIntegerNumbers = false,
    error,
    disabled = false,
    maxLength,
    min = 0,
    ...props
}: StringInputProps) {
    let currencyHtml
    if (currency) {
        currencyHtml = <div className="currency-element">€</div>
    }

    const onChangeCallback = useCallback(
        (e: React.FormEvent<HTMLInputElement>) => {
            if (onChange) {
                onChange(e.currentTarget.value)
            }
        },
        [onChange]
    )

    /**
     * Check the validation of incorrect symbols
     */
    const handleValidateIntegerNumbers: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        /**
         * Prevent user from using the letter e or the symbol -
         * see here: https://stackoverflow.com/questions/31706611/why-does-the-html-input-with-type-number-allow-the-letter-e-to-be-entered-in
         */
        if (validateIntegerNumbers && (e.key === '.' || e.key === ',' || e.keyCode === 69 || e.keyCode === 189)) {
            e.preventDefault()
        }
    }

    return (
        <div className={'relative'}>
            {currencyHtml}
            <input
                type={type}
                value={value}
                min={min}
                onChange={onChangeCallback}
                onKeyDown={handleValidateIntegerNumbers}
                className={[
                    'border border-1 px-6 py-5 text-base w-full focus:outline-none ring-0 focus:ring focus:ring-opacity-50 focus:border-black placeholder-none',
                    error
                        ? 'border-error focus:ring-error'
                        : disabled
                        ? 'border-color-none text-none'
                        : 'border-black focus:ring-transparent',
                    className,
                ].join(' ')}
                placeholder={placeholder}
                disabled={disabled}
                maxLength={maxLength}
                data-cy={props['data-cy']}
                {...props}
            />
            {error instanceof Error && <div className="text-error text-sm">{error.message}</div>}
        </div>
    )
}

function NumberInput({ type, onChange, value, ...props }: NumberInputProps) {
    return (
        <StandardInput
            type={type as Exclude<InputType, InputType.NUMBER>}
            onChange={(e: string) => onChange && onChange(parseInt(e))}
            value={value?.toString()}
            {...props}
        />
    )
}

function Input(props: InputProps) {
    if (props.type === InputType.NUMBER) {
        return <NumberInput {...props} />
    }
    return <StandardInput {...(props as StringInputProps)} />
}

export default Input
