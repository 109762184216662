import { forwardRef, useCallback } from 'react'
import Badge from '../../../components/Badge'
import { withProgram, WithProgramProps } from '../../../contexts/ProgramContext'
import { truncate } from '../../../helpers'
import DropDown from '../../../components/DropDown'
import { useTranslation } from 'react-i18next'
import { Store } from '../../../interfaces/store'
import { UseStoresListDataValidate } from '../../../hooks/stores/useStoresListData'
import { ECheckboxState, Table } from '../../../components/Table'
import { ColumnsType } from 'antd/es/table'
import { useNavigate, useLocation } from 'react-router-dom'

type StoreTableProps = {
    stores: Store[]
    editButtonActive: boolean
    methods: {
        selectedStores: string[]
        allStoresState: ECheckboxState
        loading: boolean
        toggleStore: (id: string) => void
        toggleAllStores: () => void
    }
    formData: any
    offerType?: string
} & WithProgramProps

const StoreTable = forwardRef<
    {
        validate: UseStoresListDataValidate
    },
    StoreTableProps
>((props) => {
    const { stores, methods, currentProgram } = props
    const { selectedStores, allStoresState, toggleStore, toggleAllStores, loading } = methods
    const { t } = useTranslation('common')
    const location = useLocation()
    const navigate = useNavigate()
    const disableSelectStore = !props.editButtonActive && location.pathname.endsWith('detail')

    const openStoreDetails = useCallback(
        (store: Store) => {
            navigate(`/programs/${currentProgram?.id}/stores/${store.id}/details`, {
                state: {
                    formData: props.formData,
                    prevPathName: location.pathname,
                    offerType: props.offerType,
                    offers: (location.state as any).offers,
                },
            })
        },
        [currentProgram?.id, location.pathname, navigate, props.formData, props.offerType]
    )

    const columns: ColumnsType<any> = [
        { title: t('offerCreation.store'), render: (s) => s.name },
        {
            title: t('offerCreation.address'),
            render: (s) => (s.formattedAddress ? truncate(s.formattedAddress, 20) : 'N/A'),
        },
        { title: t('offerCreation.country'), render: (s) => s.country },
        {
            title: t('offerCreation.status'),
            render: (s) =>
                s.retailerId ? (
                    <Badge appearance={'success'}>Validated</Badge>
                ) : (
                    <Badge appearance="warning">{t('offerCreation.pending')}</Badge>
                ),
        },
        {
            title: '',
            render: (s) => (
                <DropDown>
                    <span
                        className="px-4 py-3 hover:bg-gray-200 hover:text-primary w-full text-sm hover:bg-opacity-20"
                        onClick={() => openStoreDetails(s)}
                    >
                        {t('offerCreation.details')}
                    </span>
                </DropDown>
            ),
        },
    ]

    return (
        <div
            data-testid="store_table"
            className={'md:mt-20 overflow-x-scroll md:max-w-md lg:max-w-2xl xl:max-w-full m-auto'}
        >
            <Table
                columns={columns}
                data={stores}
                selectAllState={allStoresState}
                selectedIds={selectedStores}
                selectionType="checkbox"
                onToggleSelect={toggleStore}
                onToggleSelectAll={toggleAllStores}
                isSelectionDisabled={() => disableSelectStore}
                pageSize={20}
                totalElements={stores.length}
                loading={loading}
                resetPageOnDataChange={false}
            />
        </div>
    )
})

export default withProgram(StoreTable)
