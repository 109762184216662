import { useEffect, useState, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import Button from '../../../components/Button'
import BackButton from '../../../components/BackButton'
import Tabs from '../../../components/Tabs'
import Tab from '../../../components/Tab'
import { Update } from './Update'
import { ModalConfirmation } from './ModalConfirmation'
import { OffersFetcher } from '../../../data/Offers'
import DeleteOfferModal from '../../../components/DeleteOfferModal'
import { Stores } from '../common/Stores'
import moment from 'moment-timezone'
import { withProgram, WithProgramProps } from '../../../contexts/ProgramContext'
import { Offer } from '../../../interfaces/Offer'
import { UseStoresListDataProps } from '../../../hooks/stores/useStoresListData'
import { ACCESS_LEVEL, FUNCTIONALITIES } from '../../../helpers'
import RequireAuth from '../../../components/RequireAuth'
import ErrorManagementContext from '../../../contexts/ErrorManagementContext'

type DetailProps = WithProgramProps

/**
 * Display the information regarding the challenge selected
 * The user is able to edit the challenge ONLY if the challenge has not started yet
 * @return {JSX.Element}
 * @constructor
 */
const Detail = ({ currentProgram }: DetailProps) => {
    const [current, setCurrent] = useState('set-up')
    const [editButtonActive, setEditButtonActive] = useState(false)
    const [saveButtonActive, setSaveButtonActive] = useState(true)
    const [confirmationModal, setConfirmationModalOpen] = useState(false)
    const [data, setData] = useState<Offer>()
    const [rewardUnit, setRewardUnit] = useState('€')
    const [updateData, setUpdateData] = useState<any>({})
    const [deactivateOfferModal, setDeactivateOfferModal] = useState(false)
    const { t } = useTranslation('common')
    const { handleError } = useContext(ErrorManagementContext)
    const [addedStoreIds, setAddedStoreIds] = useState<string[]>([])
    const [removedStoreIds, setRemovedStoreIds] = useState<string[]>([])

    const { programId, offerId } = useParams<{
        programId?: string
        offerId?: string
    }>()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (location?.state) {
            setData(location.state as Offer)
            if ((location.state as Offer).rewardLabel?.match(/%/)) {
                setRewardUnit('%')
            }
        } else {
            OffersFetcher.get(currentProgram?.id || programId, '')
                .then((res: any) => {
                    const offerDetail = res?.data.find((item: Offer) => item.id === offerId)
                    setData(offerDetail)
                })
                .catch(handleError('errors.offers.getByProgramId'))
        }
    }, [location.state, currentProgram, programId, editButtonActive])

    const patchOffer = useCallback(() => {
        if (!data) {
            return
        }

        const body = {
            ...updateData,
            storeChanges: {
                add: [...addedStoreIds],
                remove: [...removedStoreIds],
            },
            challengeId: data.id,
        }
        const header = {
            conditionType: data.conditionType,
        }
        OffersFetcher.patch(body, header)
            .then(() => navigate(`/programs/${currentProgram?.id}/offers`))
            .catch((err) => {
                setConfirmationModalOpen(false)
                handleError('errors.offers.detail.update')(err)
            })
    }, [currentProgram?.id, data, navigate, updateData, addedStoreIds, removedStoreIds])

    const onCancel = () => {
        setEditButtonActive(!editButtonActive)
        setConfirmationModalOpen(false)
        setCurrent('set-up')
        setUpdateData({}) // reset the value
    }

    const onChange = (e: Partial<Offer>) => {
        if (e.rewardValue) {
            setUpdateData({ ...updateData, rewardValue: Number(e.rewardValue) })
        } else if (e.endDate) {
            // Shift the endDate to the program's timezone
            const programLocalEndDate = currentProgram?.timezone
                ? moment(e.endDate).tz(currentProgram?.timezone, true).toDate()
                : e.endDate
            setUpdateData({ ...updateData, endDate: programLocalEndDate })
        } else if (e.displayed === true || e.displayed === false) {
            setUpdateData({ ...updateData, displayed: e.displayed })
        }
    }

    const hasError = (e: boolean) => setSaveButtonActive(!e)

    /**
     * Display the edit button element if :
     * - The challenge has not started yet
     * - The edit button has been clicked on then display save and cancel button
     * @return {JSX.Element|null}
     */
    const displayEditButton = () => {
        const content = !editButtonActive ? (
            <div>
                <Button onClick={() => setEditButtonActive(!editButtonActive)} data-cy="offer_detail_edit_button">
                    {t('offerCreation.edit')}
                </Button>
            </div>
        ) : (
            <div>
                <Button
                    data-cy="offer_detail_delete_button"
                    onClick={() => setDeactivateOfferModal(true)}
                    className="mr-3"
                >
                    {t('offerDelete.title')}
                </Button>
                <Button
                    data-cy="offer_detail_save_button"
                    className="mr-3"
                    onClick={() => setConfirmationModalOpen(true)}
                    disabled={!saveButtonActive}
                >
                    {t('Reset_password.buttonLabel')}
                </Button>
                <Button data-cy="offer_detail_cancel_button" onClick={onCancel}>
                    {t('offerCreation.createModal.cancel')}
                </Button>
            </div>
        )

        return (
            <RequireAuth functionality={FUNCTIONALITIES.OFFER} level={ACCESS_LEVEL.WRITE}>
                {content}
            </RequireAuth>
        )
    }

    const deactivateOffer = () => {
        if (!data) {
            return
        }

        OffersFetcher.patch({ challengeId: data.id, active: false })
            .then(() => navigate(`/programs/${currentProgram?.id}/offers`))
            .catch(handleError('errors.offers.detail.deactivate'))
    }

    const onStoresChange: UseStoresListDataProps['onChange'] = ({ added, removed }) => {
        setAddedStoreIds(added)
        setRemovedStoreIds(removed)
    }

    const renderOfferType = () => {
        if (data?.offerType === 'average') return 'average_basket'
        else if (data?.offerType === 'frequency' && data?.nbTransactionPerReward === 0) return 'purchase_frequency'
        else if (data?.offerType === 'frequency' && data?.nbTransactionPerReward === 1)
            return 'purchase_frequency_non_cumulative'
        else if (data?.offerType === 'referral') return 'referral_offer'
        else if (data?.offerType === 'welcome') return 'welcome_offer'
    }

    return (
        <>
            <div className={'p-1 pl-4'}>
                <div className="md:flex md:justify-between mb-8">
                    <div className={'md:flex'}>
                        <BackButton onClick={() => navigate(`/programs/${currentProgram?.id}/offers`)} />
                        <h1 className={'my-6 md:my-0 text-2xl md:ml-6'}>{data?.teaser}</h1>
                        <div className={'my-6 md:my-0 text-lg md:ml-4 pt-1 text-gray-400'}>
                            {t(`offerCreation.offerType.${renderOfferType()}`)}
                        </div>
                    </div>
                    {displayEditButton()}
                </div>

                <div className={'lg:flex lg:justify-between'}>
                    <Tabs current={current} setCurrent={setCurrent}>
                        <Tab data-cy="offer_detail_set_up_tab" name="set-up">
                            {t('offerDetails.setUp')}
                        </Tab>
                        {data?.conditionType === 'SPND' && (
                            <Tab data-cy="offer_detail_stores_tab" name="stores">
                                {t('offerDetails.stores')}
                            </Tab>
                        )}
                    </Tabs>
                </div>

                {current === 'set-up'
                    ? data && (
                          <Update
                              formData={{
                                  ...data,
                                  displayed: updateData.displayed ?? data.displayed,
                              }}
                              editButtonActive={editButtonActive}
                              onChange={onChange}
                              hasError={hasError}
                              currentProgram={currentProgram}
                          />
                      )
                    : data && (
                          <Stores
                              currentProgram={currentProgram}
                              challengeId={data.id}
                              editButtonActive={editButtonActive}
                              APIroute={'backoffice/shoppingHubs/challenges/stores'}
                              onChange={onStoresChange}
                          />
                      )}

                <ModalConfirmation
                    confirmationModal={confirmationModal}
                    unit={rewardUnit}
                    setConfirmationModalOpen={setConfirmationModalOpen}
                    onSave={() => patchOffer()}
                    headerText={t('offerUpdate.title', { offerName: data?.teaser })}
                    updatedData={updateData}
                    storesAdded={addedStoreIds.length}
                    storesRemoved={removedStoreIds.length}
                    currentProgramTimeZone={currentProgram?.timezone}
                />
                <DeleteOfferModal
                    show={deactivateOfferModal}
                    onClose={() => setDeactivateOfferModal(false)}
                    onDelete={() => deactivateOffer()}
                    name={data?.teaser}
                />
            </div>
        </>
    )
}

export default withProgram(Detail)
