export enum ShoppingHubStatusEnum {
    LIVE = 'Live',
    VALIDATED = 'Validated',
    PENDING = 'Pending',
    TERMINATED = 'Terminated',
}

export const getStatusFromShoppingHub = (program: any) => {
    if (program.programShutdownDate && Date.parse(program.programShutdownDate) < Date.now()) return 'Terminated'
    else if (program.programLaunchDate && Date.parse(program.programLaunchDate) <= Date.now()) return 'Live'
    else if (program.missingMandatoryFields && program.missingMandatoryFields.length > 0) return 'Pending'
    else return 'Validated'
}
