type KpiDistinctVisitedStoreProps = {
    className?: string
}

function KpiDistinctVisitedStore({ className }: KpiDistinctVisitedStoreProps) {
    return (
        <svg viewBox="0 0 56 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path opacity="0.45" d="M28 21.0259C43.464 21.0259 56 19.1976 56 16.9422C56 14.6867 43.464 12.8584 28 12.8584C12.536 12.8584 0 14.6867 0 16.9422C0 19.1976 12.536 21.0259 28 21.0259Z" fill="#E6E6E6" />
            <path d="M35.0248 10.2178V12.6569C35.0248 13.762 31.9512 14.648 28.1628 14.648C24.3744 14.648 21.3048 13.7401 21.3068 12.635V10.1959L35.0248 10.2178Z" fill="#FFD200" />
            <path d="M35.0208 13.1603V15.5994C35.0208 16.7045 31.9452 17.5906 28.1568 17.5906C24.3684 17.5906 21.2988 16.6826 21.3008 15.5775V13.1384L35.0208 13.1603Z" fill="#FFD200" />
            <path opacity="0.08" d="M35.0208 13.1603V15.5994C35.0208 16.7045 31.9452 17.5906 28.1568 17.5906C24.3684 17.5906 21.2988 16.6826 21.3008 15.5775V13.1384L35.0208 13.1603Z" fill="black" />
            <path d="M34.8917 13.0829C34.8936 11.9876 31.8522 11.0944 28.0987 11.0878C24.3451 11.0813 21.3006 11.9638 21.2987 13.0591C21.2968 14.1543 24.3382 15.0476 28.0918 15.0541C31.8453 15.0607 34.8898 14.1781 34.8917 13.0829Z" fill="#FFD200" />
            <path opacity="0.08" d="M35.0248 10.2178V12.6569C35.0248 13.762 31.9512 14.648 28.1628 14.648C24.3744 14.648 21.3048 13.7401 21.3068 12.635V10.1959L35.0248 10.2178Z" fill="black" />
            <path d="M34.5321 7.30602V9.74514C34.5321 10.8502 31.4565 11.7363 27.6681 11.7363C23.8797 11.7363 20.81 10.8283 20.812 9.72324V7.28412L34.5321 7.30602Z" fill="#FFD200" />
            <path d="M35.0346 4.36732V6.80644C35.0346 7.91152 31.961 8.79756 28.1726 8.79756C24.3842 8.79756 21.3145 7.88961 21.3165 6.78454V4.34741L35.0346 4.36732Z" fill="#FFD200" />
            <path d="M34.8977 10.1691C34.8996 9.07379 31.8582 8.18058 28.1046 8.17401C24.3511 8.16744 21.3066 9.05 21.3047 10.1453C21.3028 11.2405 24.3442 12.1337 28.0977 12.1403C31.8513 12.1469 34.8958 11.2643 34.8977 10.1691Z" fill="#FFD200" />
            <path opacity="0.08" d="M34.5321 7.30602V9.74514C34.5321 10.8502 31.4565 11.7363 27.6681 11.7363C23.8797 11.7363 20.81 10.8283 20.812 9.72324V7.28412L34.5321 7.30602Z" fill="black" />
            <path d="M34.4066 7.28106C34.4085 6.1858 31.3671 5.29259 27.6136 5.28602C23.86 5.27945 20.8155 6.16201 20.8136 7.25727C20.8117 8.35253 23.8531 9.24574 27.6066 9.25231C31.3602 9.25888 34.4047 8.37632 34.4066 7.28106Z" fill="#FFD200" />
            <path opacity="0.08" d="M35.0346 4.36732V6.80644C35.0346 7.91152 31.961 8.79756 28.1726 8.79756C24.3842 8.79756 21.3145 7.88961 21.3165 6.78454V4.34741L35.0346 4.36732Z" fill="black" />
            <path d="M35.0366 4.37089C35.0386 3.27563 31.9679 2.38236 28.1781 2.37573C24.3883 2.3691 21.3146 3.25161 21.3127 4.34687C21.3107 5.44213 24.3814 6.33539 28.1712 6.34203C31.961 6.34866 35.0347 5.46615 35.0366 4.37089Z" fill="#FFD200" />
            <path d="M50.9606 9.72565V12.4555C50.9606 13.692 47.5177 14.6895 43.2786 14.6815C39.0395 14.6736 35.6025 13.6661 35.6045 12.4296V9.69977L50.9606 9.72565Z" fill="#FFD200" />
            <path d="M50.955 13.0151V15.7449C50.955 16.9834 47.5141 17.979 43.273 17.973C39.0319 17.967 35.5969 16.9575 35.5989 15.719V12.9892L50.955 13.0151Z" fill="#FFD200" />
            <path opacity="0.08" d="M50.955 13.0151V15.7449C50.955 16.9834 47.5141 17.979 43.273 17.973C39.0319 17.967 35.5969 16.9575 35.5989 15.719V12.9892L50.955 13.0151Z" fill="black" />
            <path d="M50.8104 12.9269C50.8125 11.7007 47.4087 10.7008 43.2077 10.6935C39.0067 10.6861 35.5994 11.6741 35.5972 12.9002C35.5951 14.1264 38.999 15.1263 43.2 15.1336C47.4009 15.141 50.8083 14.153 50.8104 12.9269Z" fill="#FFD200" />
            <path opacity="0.08" d="M50.9606 9.72565V12.4555C50.9606 13.692 47.5177 14.6895 43.2786 14.6815C39.0395 14.6736 35.6025 13.6661 35.6045 12.4296V9.69977L50.9606 9.72565Z" fill="black" />
            <path d="M50.4065 6.46201V9.19183C50.4065 10.4303 46.9656 11.4259 42.7245 11.4199C38.4834 11.4139 35.0484 10.4044 35.0504 9.16595V6.43613L50.4065 6.46201Z" fill="#FFD200" />
            <path d="M50.9706 3.17463V5.90445C50.9706 7.14093 47.5297 8.13849 43.2886 8.13052C39.0475 8.12256 35.6125 7.11505 35.6145 5.87857V3.14874L50.9706 3.17463Z" fill="#FFD200" />
            <path d="M50.816 9.66749C50.8181 8.44137 47.4143 7.44144 43.2133 7.43409C39.0123 7.42673 35.605 8.41474 35.6029 9.64087C35.6007 10.867 39.0046 11.8669 43.2056 11.8743C47.4066 11.8816 50.8139 10.8936 50.816 9.66749Z" fill="#FFD200" />
            <path opacity="0.08" d="M50.4065 6.46201V9.19183C50.4065 10.4303 46.9656 11.4259 42.7245 11.4199C38.4834 11.4139 35.0484 10.4044 35.0504 9.16595V6.43613L50.4065 6.46201Z" fill="black" />
            <path d="M50.2691 6.43462C50.2713 5.20849 46.8674 4.20856 42.6664 4.20121C38.4654 4.19385 35.0581 5.18187 35.056 6.40799C35.0539 7.63411 38.4577 8.63404 42.6587 8.6414C46.8597 8.64875 50.267 7.66074 50.2691 6.43462Z" fill="#FFD200" />
            <path opacity="0.08" d="M50.9706 3.17463V5.90445C50.9706 7.14093 47.5297 8.13849 43.2886 8.13052C39.0475 8.12256 35.6125 7.11505 35.6145 5.87857V3.14874L50.9706 3.17463Z" fill="black" />
            <path d="M50.9731 3.1755C50.9752 1.94938 47.5385 0.94939 43.2969 0.941966C39.0553 0.934542 35.6151 1.92249 35.613 3.14862C35.6109 4.37474 39.0476 5.37473 43.2892 5.38216C47.5307 5.38958 50.9709 4.40162 50.9731 3.1755Z" fill="#FFD200" />
            <path d="M20.0695 9.52213V12.252C20.0695 13.4884 16.6266 14.486 12.3875 14.478C8.14835 14.4701 4.71139 13.4625 4.71338 12.2261V9.49625L20.0695 9.52213Z" fill="#FFD200" />
            <path d="M20.0581 12.8061V15.5359C20.0581 16.7724 16.6172 17.77 12.3761 17.762C8.13501 17.754 4.70004 16.7465 4.70203 15.51V12.7802L20.0581 12.8061Z" fill="#FFD200" />
            <path opacity="0.08" d="M20.0581 12.8061V15.5359C20.0581 16.7724 16.6172 17.77 12.3761 17.762C8.13501 17.754 4.70004 16.7465 4.70203 15.51V12.7802L20.0581 12.8061Z" fill="black" />
            <path d="M19.919 12.7242C19.9212 11.4981 16.5173 10.4981 12.3163 10.4908C8.11533 10.4834 4.70802 11.4714 4.70589 12.6976C4.70376 13.9237 8.1076 14.9236 12.3086 14.931C16.5096 14.9383 19.9169 13.9503 19.919 12.7242Z" fill="#FFD200" />
            <path opacity="0.08" d="M20.0695 9.52213V12.252C20.0695 13.4884 16.6266 14.486 12.3875 14.478C8.14835 14.4701 4.71139 13.4625 4.71338 12.2261V9.49625L20.0695 9.52213Z" fill="black" />
            <path d="M19.5158 6.25916V8.98898C19.5158 10.2255 16.0749 11.223 11.8338 11.215C7.59266 11.2071 4.15768 10.1996 4.15967 8.9631V6.23328L19.5158 6.25916Z" fill="#FFD200" />
            <path d="M20.0795 2.9697V5.69952C20.0795 6.93601 16.6386 7.93356 12.3975 7.92758C8.15638 7.92161 4.7214 6.91012 4.72339 5.67364V2.94382L20.0795 2.9697Z" fill="#FFD200" />
            <path d="M19.9251 9.46464C19.9273 8.23852 16.5234 7.23859 12.3224 7.23124C8.12143 7.22388 4.71413 8.21189 4.71199 9.43802C4.70986 10.6641 8.11371 11.6641 12.3147 11.6714C16.5157 11.6788 19.923 10.6908 19.9251 9.46464Z" fill="#FFD200" />
            <path opacity="0.08" d="M19.5158 6.25916V8.98898C19.5158 10.2255 16.0749 11.223 11.8338 11.215C7.59266 11.2071 4.15768 10.1996 4.15967 8.9631V6.23328L19.5158 6.25916Z" fill="black" />
            <path d="M19.3759 6.23107C19.3781 5.00494 15.9742 4.00501 11.7732 3.99766C7.57224 3.99031 4.16493 4.97832 4.1628 6.20444C4.16067 7.43056 7.56451 8.43049 11.7655 8.43784C15.9665 8.4452 19.3738 7.45719 19.3759 6.23107Z" fill="#FFD200" />
            <path opacity="0.08" d="M20.0795 2.9697V5.69952C20.0795 6.93601 16.6386 7.93356 12.3975 7.92758C8.15638 7.92161 4.7214 6.91012 4.72339 5.67364V2.94382L20.0795 2.9697Z" fill="black" />
            <path opacity="0.08" d="M19.7777 18.2791C22.926 18.2791 25.4782 15.7198 25.4782 12.5626C25.4782 9.40549 22.926 6.84613 19.7777 6.84613C16.6295 6.84613 14.0773 9.40549 14.0773 12.5626C14.0773 15.7198 16.6295 18.2791 19.7777 18.2791Z" fill="black" />
            <path opacity="0.08" d="M15.9853 6.83261C15.9853 6.83261 10.0187 8.65846 10.9758 17.72C10.9758 17.72 14.8674 17.8515 16.714 17.3776C18.3639 16.9594 15.9853 6.83261 15.9853 6.83261Z" fill="black" />
            <path d="M20.0818 2.97195C20.084 1.74583 16.6472 0.745841 12.4057 0.738418C8.16409 0.730994 4.72389 1.71895 4.72176 2.94507C4.71963 4.17119 8.15636 5.17118 12.3979 5.1786C16.6395 5.18603 20.0797 4.19808 20.0818 2.97195Z" fill="#FFD200" />
            <path d="M19.7777 18.2791C22.926 18.2791 25.4782 15.7198 25.4782 12.5626C25.4782 9.40549 22.926 6.84613 19.7777 6.84613C16.6295 6.84613 14.0773 9.40549 14.0773 12.5626C14.0773 15.7198 16.6295 18.2791 19.7777 18.2791Z" fill="#FFD200" />
            <path opacity="0.08" d="M19.7777 18.2791C22.926 18.2791 25.4782 15.7198 25.4782 12.5626C25.4782 9.40549 22.926 6.84613 19.7777 6.84613C16.6295 6.84613 14.0773 9.40549 14.0773 12.5626C14.0773 15.7198 16.6295 18.2791 19.7777 18.2791Z" fill="black" />
            <g opacity="0.08">
                <path opacity="0.08" d="M20.0559 16.5593C23.2042 16.5593 25.7564 14 25.7564 10.8428C25.7564 7.68571 23.2042 5.12634 20.0559 5.12634C16.9077 5.12634 14.3555 7.68571 14.3555 10.8428C14.3555 14 16.9077 16.5593 20.0559 16.5593Z" fill="black" />
            </g>
            <path d="M20.0559 16.5594C23.2042 16.5594 25.7564 14 25.7564 10.8429C25.7564 7.68574 23.2042 5.12637 20.0559 5.12637C16.9077 5.12637 14.3555 7.68574 14.3555 10.8429C14.3555 14 16.9077 16.5594 20.0559 16.5594Z" fill="#FFD200" />
            <path d="M20.2386 8.28558C20.4014 8.28558 20.449 8.34133 20.449 8.50261V8.84708C20.7325 8.87689 21.0025 8.98368 21.2299 9.15595C21.4573 9.32822 21.6336 9.55944 21.7396 9.82472C21.823 9.99197 21.7893 10.0836 21.5987 10.1692L21.2552 10.3225C21.0943 10.4001 21.0169 10.3643 20.9256 10.1971C20.8543 10.0222 20.7286 9.87506 20.5673 9.77749C20.406 9.67992 20.2176 9.63713 20.0301 9.65547C19.4344 9.65547 19.1604 9.83069 19.1604 10.2309C19.1742 10.3533 19.2297 10.4672 19.3177 10.5532C19.4056 10.6392 19.5205 10.6921 19.6429 10.7028C19.8099 10.7392 19.979 10.7652 20.1492 10.7805C20.4668 10.7948 20.7806 10.8558 21.0804 10.9616C21.2308 11.0105 21.3708 11.0868 21.4934 11.1866C21.6176 11.3179 21.7144 11.4727 21.7781 11.6419C21.8419 11.8112 21.8713 11.9915 21.8647 12.1722C21.8647 12.9368 21.3544 13.4585 20.449 13.5919V13.9563C20.449 14.1176 20.4014 14.1753 20.2386 14.1753H19.8593C19.6985 14.1753 19.6429 14.1176 19.6429 13.9563V13.6019C19.3287 13.5813 19.0277 13.4675 18.7781 13.275C18.5285 13.0825 18.3416 12.8199 18.2411 12.5207C18.2263 12.4892 18.2188 12.4547 18.2191 12.4198C18.2195 12.3849 18.2277 12.3506 18.2432 12.3194C18.2587 12.2881 18.281 12.2608 18.3085 12.2395C18.336 12.2181 18.3679 12.2033 18.402 12.1961L18.7455 12.0707C18.9202 12.007 18.9976 12.0488 19.0751 12.224C19.2299 12.6222 19.5456 12.8213 20.0222 12.8213C20.6655 12.8213 20.9891 12.6222 20.9891 12.2021C20.994 12.1062 20.967 12.0113 20.9124 11.9324C20.8578 11.8535 20.7786 11.795 20.6873 11.7661C20.4636 11.6871 20.2293 11.6422 19.9924 11.6326C19.6709 11.6153 19.3541 11.5482 19.0532 11.4335C18.9084 11.3796 18.774 11.3007 18.6561 11.2006C18.5303 11.0763 18.4317 10.9272 18.3664 10.7627C18.3011 10.5981 18.2706 10.4218 18.2769 10.2448C18.2769 9.48623 18.7534 8.98048 19.6509 8.86102V8.51655C19.6509 8.35527 19.7065 8.29952 19.8673 8.29952L20.2386 8.28558Z" fill="white" />
        </svg>
    )
}

export default KpiDistinctVisitedStore
