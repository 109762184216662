import { useState, useMemo, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

import Button, { ButtonAppearance } from '../../../../components/Button'
import { withProgram, WithProgramProps } from '../../../../contexts/ProgramContext'
import DropdownInput from '../../../../components/DropdownInput'
import CopyToClipboardIcon from '../../../../components/Icons/CopyToClipboardIcon'
import InfoTooltip from '../../../../components/InfoTooltip'
import Badge from '../../../../components/Badge'
import SuccessIcon from '../../../../components/Icons/SuccessIcon'
import WarningIcon from '../../../../components/Icons/WarningIcon'
import ErrorIcon from '../../../../components/Icons/ErrorIcon'
import RestTable from '../../../../components/Table'
import { ColumnsType } from 'antd/es/table'
import ErrorManagementContext from '../../../../contexts/ErrorManagementContext'
import { Request } from '../../../../helpers'
import YLogoLoading from '../../../../components/Icons/YLogoLoading'
import * as Tailwind from '../../../../../tailwind.config'

type CustomerListingProps = WithProgramProps & {
    filtersQueryParams: string
}

function Listing({ currentProgram, filtersQueryParams }: CustomerListingProps) {
    const { t } = useTranslation('common')
    const url = `backoffice/v3/customers`
    const [csvLoading, setCsvLoading] = useState<boolean>(false)
    const [csvPercent, setCsvPercent] = useState<number>()
    const [search, setSearch] = useState<string>()
    const [copyResult, setCopyResult] = useState<string>()
    const navigate = useNavigate()
    const { handleError } = useContext(ErrorManagementContext)

    const { programId } = useParams<{ programId: string }>()

    const addHubFilter = (baseUrl: string) => {
        let shoppingHubId = currentProgram?.shoppingHubId || programId
        let shoppingHubOwnerId = !shoppingHubId && currentProgram?.shoppingHubOwnerId
        let shoppingHub = '?'
        if (shoppingHubId) {
            shoppingHub += `shoppingHubId=${shoppingHubId}&`
        } else if (shoppingHubOwnerId) {
            shoppingHub += `shoppingHubOwnerId=${shoppingHubOwnerId}&`
        }

        return baseUrl + shoppingHub
    }

    const tableQueryUrl = useMemo(() => {
        return addHubFilter(url) + (!!search ? `search=${encodeURIComponent(search)}&` : '') + filtersQueryParams
    }, [search, filtersQueryParams])

    const handleCopyToClipboard = async (customerId: string) => {
        try {
            await navigator.clipboard.writeText(customerId)
            setCopyResult(t('audience.usersList.successCopied'))
        } catch (err) {
            setCopyResult(t('audience.usersList.errorCopied'))
        }
    }

    const getBadgeAppearance = (customer: any) => {
        if (customer.dataRetrievalMethod === 'AL') {
            if (customer.shopperStatus === 'synchronized') {
                return 'success'
            } else if (customer.shopperStatus === 'soonDesynchronized') {
                return 'warning'
            } else {
                return 'error'
            }
        } else {
            return customer.isActive ? 'success' : 'error'
        }
    }

    const mapMethodToLocizeKey: Record<string, string> = {
        SCAN: 'user.listing.experience.scan',
        AL: 'user.listing.experience.accountLinking',
        CL: 'user.listing.experience.cardLinking',
    }

    const handleDataRetrievalMethod = (customer: any) => {
        const locizeKey = mapMethodToLocizeKey[customer.dataRetrievalMethod]
        const badgeAppearance = getBadgeAppearance(customer)
        return locizeKey ? (
            <Badge
                appearance={badgeAppearance}
                className="mr-2 inline-flex"
                key={customer.id + customer.dataRetrievalMethod}
            >
                {t(locizeKey)}

                {badgeAppearance === 'success' ? <SuccessIcon className="ml-2 mt-auto mb-auto" /> : ''}
                {badgeAppearance === 'warning' ? <WarningIcon className="ml-2 mt-auto mb-auto" /> : ''}
                {badgeAppearance === 'error' ? <ErrorIcon className="ml-2 mt-auto mb-auto" /> : ''}
            </Badge>
        ) : (
            <Badge className="mr-2 inline-flex" key={customer.id + 'unavailable'}>
                {t('user.listing.experience.unavailable')}
            </Badge>
        )
    }

    const customerToNameOrId = (c: any) => (c?.firstName && c?.lastName ? `${c.firstName} ${c.lastName}` : c.externalId)
    const columns: ColumnsType<any> = [
        {
            title: t('audience.usersList.header.id'),
            render: (c) => (
                <div className="w-32 truncate" title={customerToNameOrId(c)}>
                    {customerToNameOrId(c)}
                </div>
            ),
        },
        {
            title: '',
            render: (c) => (
                <div onClick={() => handleCopyToClipboard(c.id)}>
                    <InfoTooltip
                        text={copyResult || t('audience.usersList.successCopied')}
                        element={<CopyToClipboardIcon />}
                    />
                </div>
            ),
        },
        {
            title: t('audience.usersList.header.registration'),
            key: 'registrationDate',
            render: (c) => (
                <span className="transtion-none">
                    {c.registrationDate
                        ? moment(c.registrationDate).format('DD/MM/YYYY')
                        : t('audience.usersList.notFullyRegistered')}
                </span>
            ),
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'ascend',
        },
        {
            title: t('audience.usersList.header.program'),
            key: 'shoppingHubName',
            render: (c) => c.shoppingHub,
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        { title: t('audience.usersList.header.retrievalMethod'), render: (c) => handleDataRetrievalMethod(c) },
    ]

    const exportCsv = async () => {
        setCsvLoading(true)
        setCsvPercent(0)
        try {
            const today = moment().format('YYYY-MM-DD')
            const batchSize = 100
            let csvUrl = `${addHubFilter('backoffice/v3/customers/csv')}limit=${batchSize}`
            const allCustomers = []
            let res = null
            do {
                res = await Request.get<any>(`${csvUrl}&offset=${allCustomers.length}`)
                allCustomers.push(...res.items)
                setCsvPercent(Math.round((allCustomers.length * 100) / res.totalItems))
            } while (allCustomers.length < res.totalItems)

            let csv =
                'externalId,email,first_name,last_name,created_at,has_transactions,number_of_rewards,total_rewards_amount\n'
            for (const c of allCustomers) {
                const createdAt = c.createdAt ? c.createdAt.replace('T', ' ').replace('Z', '') : ''
                csv += `${c.externalId || ''},${c.email || ''},${c.firstName || ''},${c.lastName || ''},${createdAt},${
                    c.hasTransactions
                },${c.numberOfRewards},${c.totalRewardsAmount}\n`
            }

            const link = document.createElement('a')
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
            const url = URL.createObjectURL(blob)
            link.href = url
            link.setAttribute('download', `${today}_members.csv`)
            document.body.appendChild(link)
            link.click()
            link.remove()
            URL.revokeObjectURL(url)
        } catch (err) {
            handleError('errors.members.create.export_csv')(err)
        } finally {
            setCsvLoading(false)
            setCsvPercent(undefined)
        }
    }

    return (
        <div className="h-full">
            <div className="flex flex-col h-full">
                <div className="flex flex-col gap-4 flex-1">
                    <div className="grid grid-cols-1 gap-4">
                        <div className="w-full flex justify-between gap-2">
                            <div className="2xl:w-1/3 lg:w-3/4 w-full flex gap-2">
                                <DropdownInput
                                    onChange={(e) => setSearch(e.value)}
                                    options={[]}
                                    placeholder={t('listing_program.input_search_btn')}
                                    value={search}
                                    asSearchInput
                                    withoutMenu
                                    className="h-full"
                                    data-cy="search_input"
                                />
                                <Button
                                    disabled={false}
                                    appearance={ButtonAppearance.BUTTON_APPEARANCE_OUTLINE}
                                    onClick={() => setSearch('')}
                                    data-cy="search_clear_button"
                                >
                                    {t('listingStore.filter.button.clear')}
                                </Button>
                            </div>
                            <div>
                                <Button
                                    disabled={false}
                                    appearance={ButtonAppearance.BUTTON_APPEARANCE_OUTLINE}
                                    onClick={() => exportCsv()}
                                    data-cy="export_csv_button"
                                >
                                    {csvLoading ? (
                                        <div className="w-12 flex justify-center">
                                            <YLogoLoading
                                                percentage={50}
                                                width={15}
                                                height={20}
                                                border="white"
                                                color={(Tailwind as any).theme.extend.colors.primary}
                                            />
                                            {csvPercent !== undefined ? csvPercent + '%' : ''}
                                        </div>
                                    ) : (
                                        t('listingStore.button.export_csv')
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 overflow-x-scroll">
                        <RestTable
                            queryBaseUrl={tableQueryUrl}
                            columns={columns}
                            errorMessageKey="errors.customers.search"
                            totalItemsKey="totalItems"
                            pageResultsKey="items"
                            onRowClicked={(c) => navigate(`shopper-profile/${c.id}`, { state: { customer: c } })}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withProgram(Listing)
