import { Checkbox, Table as AntdTable, TableProps as AntdTableProps } from 'antd'
import { ColumnsType } from 'antd/es/table'
import {
    ForwardedRef,
    forwardRef,
    ReactNode,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react'
import { Request } from '../helpers'
import './Table.css'
import {
    FilterValue,
    SorterResult,
    TableCurrentDataSource,
    TablePaginationConfig,
    TableRowSelection,
} from 'antd/lib/table/interface'
import ReactTooltip from 'react-tooltip'
import ErrorManagementContext from '../contexts/ErrorManagementContext'

/**
 * Each item should have an id
 */
interface Item {
    id: string
}

/**
 * The result from the API call should have this data shape
 */
export interface PageItem<TItem extends Item> {
    /**
     * Items of the requested page
     */
    rows: TItem[]

    /**
     * Total number of item matching the request (for pagination)
     */
    count: number

    /**
     * Some other properties
     */
    [x: string]: unknown
}

/**
 * Rest table properties
 */
type RestTableProps<TItem extends Item> = {
    // Base query url. Parameters order, orderBy, limit and offset will be added
    queryBaseUrl: string

    // Custom headers
    headers?: Record<string, string>

    // Base locize error key for REST API call
    errorMessageKey: string

    // Hook to edit/access data when receiving API call response
    onDataReceived?: (page: PageItem<TItem>) => void

    // Hook when there are no data
    onNoData?: () => void

    // Object key that represent the rows in the API response object
    pageResultsKey?: string | null

    // Object key that represent the total number of item in the API response object (for pagination)
    totalItemsKey?: string
} & TableProps<TItem>

// Type simplification (see Antd Table.onChange)
type OnChangeFunc<TItem extends Item> = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TItem> | SorterResult<TItem>[],
    extra: TableCurrentDataSource<TItem>
) => void

enum EOrderType {
    ASC = 'ASC',
    DESC = 'DESC',
}

interface Sort {
    // Field sorted
    by: string

    // Sort type
    type: EOrderType
}

/**
 * Look for a default sort in columns definition
 */
const getDefaultSort = <TItem extends Item>(columns: ColumnsType<TItem>): Sort | undefined => {
    const withDefaultSort = columns.find((c) => c.defaultSortOrder)
    if (withDefaultSort)
        return {
            by: withDefaultSort.key as string,
            type: withDefaultSort.defaultSortOrder === 'descend' ? EOrderType.DESC : EOrderType.ASC,
        }
}

/**
 * RestTable ref type
 */
export type RestTableRef = {
    refresh: () => void
}

/**
 * REST managed table
 * This table manage REST queries with sorts and pagination
 */
const RestTable = forwardRef(
    <TItem extends Item>(
        {
            queryBaseUrl,
            pageSize = 20,
            headers = {},
            columns,
            onDataReceived = (p) => p,
            errorMessageKey,
            onNoData = () => {},
            onRowClicked,
            pageResultsKey = 'rows',
            totalItemsKey = 'count',
            ...rest
        }: RestTableProps<TItem>,
        ref: ForwardedRef<RestTableRef>
    ) => {
        const [loading, setLoading] = useState(true)
        const [current, setCurrent] = useState(1)
        const [total, setTotal] = useState(0)
        const [pageData, setPageData] = useState<TItem[]>([])
        const [limit, setLimit] = useState(pageSize)
        const [sort, setSort] = useState<Sort | undefined>(getDefaultSort(columns))
        const firstUpdate = useRef(true)
        const { handleError } = useContext(ErrorManagementContext)

        const refresh = () => {
            if (!queryBaseUrl) return

            setLoading(true)

            const offset = (current - 1) * limit
            let url = `${queryBaseUrl}${queryBaseUrl.indexOf('?') > 0 ? '&' : '?'}`
            url += `limit=${limit}&offset=${offset}`

            if (sort) url += `&order=${sort.type}&orderBy=${sort.by}`

            Request.get<PageItem<TItem>>(url, headers)
                .then((data) => {
                    onDataReceived(data)

                    setPageData((pageResultsKey !== null ? data[pageResultsKey] : data) as TItem[])
                    setTotal(data[totalItemsKey] as number)

                    // Handle onNoData hook
                    if (firstUpdate.current) {
                        firstUpdate.current = false
                        if (data.count === 0) {
                            onNoData()
                        }
                    }
                })
                .catch(handleError(errorMessageKey))
                .finally(() => setLoading(false))
        }

        useImperativeHandle(ref, () => ({ refresh }))

        // Update data
        useEffect(refresh, [limit, queryBaseUrl, current, sort])

        useEffect(() => setCurrent(1), [queryBaseUrl])

        return (
            <>
                <Table
                    {...rest}
                    columns={columns}
                    currentPage={current}
                    onPageChanged={(i) => setCurrent(i)}
                    pageSize={limit}
                    totalElements={total}
                    data={pageData}
                    loading={loading}
                    rowClassName={(record, index, indent) => {
                        let className = !!onRowClicked ? 'cursor-pointer ' : ''
                        if (rest.rowClassName) {
                            className +=
                                typeof rest.rowClassName === 'function'
                                    ? rest.rowClassName(record, index, indent)
                                    : rest.rowClassName
                        }

                        return className
                    }}
                    onPageSizeChanged={setLimit}
                    onRow={(item) => ({
                        onClick: () => onRowClicked?.(item),
                    })}
                    onSortChanged={setSort}
                    resetPageOnDataChange={false}
                />
            </>
        )
    }
)

export enum ECheckboxState {
    UNCHECKED,
    INDETERMINATE,
    CHECKED,
}

/**
 * Simple table props
 */
type TableProps<TItem extends Item> = {
    // Rows displayed
    data?: TItem[]

    // Number of element per page (limit)
    pageSize?: number

    // Column definition (see Antd Table Columns)
    columns: ColumnsType<TItem>

    // Total number of element (for pagination)
    totalElements?: number

    onRowClicked?: (item: TItem) => void
    onPageChanged?: (index: number) => void
    onPageSizeChanged?: (index: number) => void
    onSortChanged?: (sort?: Sort) => void

    // Should we go back to the first page if the data changed ?
    resetPageOnDataChange?: boolean
    selectionType?: 'checkbox' | 'radio'
    selectedIds?: string[] | Set<string>
    onToggleSelect?: (key: string) => void
    onToggleSelectAll?: (selected: boolean) => void
    isSelectionDisabled?: (item?: TItem) => boolean
    selectAllState?: ECheckboxState
    currentPage?: number
    renderSelectionCell?: (checked: boolean, record: TItem) => ReactNode
} & WrapperTableProps<TItem>

export const Table = <TItem extends Item>({
    data,
    pageSize = 0,
    columns,
    totalElements,
    onRowClicked,
    onSortChanged = () => {},
    onPageChanged = () => {},
    onPageSizeChanged = () => {},
    resetPageOnDataChange = true,
    selectionType,
    selectedIds,
    onToggleSelect,
    onToggleSelectAll,
    isSelectionDisabled,
    selectAllState,
    currentPage,
    renderSelectionCell,
    ...antdParams
}: TableProps<TItem>) => {
    const [current, setCurrent] = useState(currentPage || 1)
    const [limit, setLimit] = useState(pageSize)
    const [dataSource, setDataSource] = useState<TItem[]>(data || [])
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([...(selectedIds || [])])

    useEffect(() => setCurrent(currentPage || current), [currentPage])
    useEffect(() => setSelectedRowKeys([...(selectedIds || [])]), [selectedIds])
    useEffect(() => setLimit(pageSize), [pageSize])
    useEffect(() => onPageSizeChanged(limit), [limit])
    useEffect(() => onPageChanged(current), [current])
    useEffect(() => {
        if (data) {
            setDataSource(data)
            if (resetPageOnDataChange) setCurrent(1)
        }
    }, [data])

    // Avoid mandatory sorter property in column if we have a sortDirections or defaultSortOrder
    const customizedColumns = useMemo(
        () =>
            columns.map((c) => {
                if ((c.sortDirections || c.defaultSortOrder) && !c.sorter) {
                    c.sorter = () => 0
                }
                return c
            }),
        [columns]
    )

    const onChange: OnChangeFunc<TItem> = (pagination, _, sorter) => {
        setCurrent(pagination.current || 1)
        setLimit(pagination.pageSize || 1)

        // Only one sort at a time
        if (!Array.isArray(sorter) && sorter.order && sorter.columnKey) {
            let type = sorter.order === 'descend' ? EOrderType.DESC : EOrderType.ASC
            onSortChanged({ by: sorter.columnKey as string, type })
        } else onSortChanged(undefined)
    }

    const pagination =
        limit > 0
            ? {
                  current: current,
                  pageSize: limit,
                  total: totalElements || 0,
              }
            : false

    const columnTitle =
        selectAllState === undefined ? undefined : (
            <span className="whitespace-pre" data-tip="tableSelection" data-for="tableSelection">
                <Checkbox
                    checked={selectAllState === ECheckboxState.CHECKED}
                    onChange={(e) => onToggleSelectAll?.(e.target.checked)}
                    indeterminate={selectAllState === ECheckboxState.INDETERMINATE}
                    disabled={isSelectionDisabled?.()}
                />

                <ReactTooltip id="tableSelection">
                    <span>
                        {selectedRowKeys.length}/{totalElements}
                    </span>
                </ReactTooltip>
            </span>
        )

    const rowSelection: TableRowSelection<TItem> | undefined = selectionType
        ? {
              type: selectionType,
              selectedRowKeys,
              onSelect: (r) => onToggleSelect?.(r.id),
              onSelectAll: (selected) => onToggleSelectAll?.(selected),
              getCheckboxProps: (item) => ({ disabled: isSelectionDisabled?.(item) }),
              preserveSelectedRowKeys: !!pagination,
              renderCell: renderSelectionCell,
              columnTitle,
          }
        : undefined

    return (
        <WrapperTable
            columns={customizedColumns}
            dataSource={dataSource}
            onChange={onChange}
            rowClassName={!!onRowClicked ? 'cursor-pointer' : ''}
            pagination={pagination}
            onRow={(item) => ({
                onClick: () => onRowClicked?.(item),
            })}
            rowSelection={rowSelection}
            {...antdParams}
        />
    )
}

/**
 * Private wrapper around Antd Table to setup common properties
 */
type WrapperTableProps<TItem extends Item> = AntdTableProps<TItem>
const WrapperTable = <TItem extends Item>(props: WrapperTableProps<TItem>) => {
    const pagination = props.pagination
        ? {
              ...props.pagination,
              pageSizeOptions: [10, 20, 50, 100, 500],
          }
        : false

    return <AntdTable {...props} rowKey="id" pagination={pagination} />
}

export default RestTable
