type KpiPurchaseIntentProps = {
    className?: string
}

function KpiPurchaseIntent({ className }: KpiPurchaseIntentProps) {
    return (
        <svg viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M21.6854 11.571H23.6713C23.8619 11.5707 24.0505 11.6094 24.2258 11.6848C24.401 11.7601 24.559 11.8705 24.6903 12.0092C24.8216 12.1478 24.9233 12.3119 24.9893 12.4914C25.0552 12.6708 25.0841 12.8619 25.074 13.0529L23.8155 37.3056C23.794 37.7219 23.614 38.114 23.3127 38.4009C23.0114 38.6879 22.6118 38.8479 22.1964 38.8478H20.6794L21.6854 11.571Z" fill="#24285B" />
            <path d="M29.8469 21.7238L30.0553 26.1373L34.2455 27.9428L36.7383 25.6165L36.4598 23.1836L32.6865 19.1884L29.8469 21.7238Z" fill="#F4A28C" />
            <path opacity="0.09" d="M29.8469 21.7238L30.0553 26.1373L34.2455 27.9428L36.7383 25.6165L36.4598 23.1836L32.6865 19.1884L29.8469 21.7238Z" fill="black" />
            <path d="M36.0045 16.2992C36.0045 16.2992 34.0708 14.001 32.4717 12.9273C29.3216 10.808 28.1793 13.066 28.3176 14.3166C28.4559 15.5673 32.1591 19.3896 32.1591 19.3896L36.0045 16.2992Z" fill="#F4A28C" />
            <path d="M4.65821 9.10083C3.98499 9.25687 3.38693 9.64326 2.96677 10.1936C2.5466 10.744 2.33056 11.424 2.35574 12.1168L3.3136 38.2557C3.33817 38.9329 3.58847 39.5824 4.02444 40.1C4.4604 40.6176 5.05683 40.9736 5.71827 41.1108C9.88759 41.9835 14.1915 41.9835 18.3609 41.1108C19.0223 40.9736 19.6187 40.6176 20.0547 40.1C20.4907 39.5824 20.741 38.9329 20.7655 38.2557L21.7234 12.1168C21.7486 11.424 21.5325 10.744 21.1124 10.1936C20.6922 9.64326 20.0941 9.25687 19.4209 9.10083C14.5634 7.97966 9.51568 7.97966 4.65821 9.10083Z" fill="#E6E6E6" />
            <path d="M18.9734 12.766H5.21265V21.8603H18.9734V12.766Z" fill="white" />
            <path d="M2.35571 12.117L1.80264 12.2055C1.5399 12.2478 1.30213 12.3863 1.13522 12.5943C0.968311 12.8022 0.88411 13.0649 0.898876 13.3315L2.31162 38.0889C2.32291 38.2945 2.41231 38.4879 2.56143 38.6293C2.71055 38.7706 2.90802 38.8493 3.11318 38.849H3.43982L2.35571 12.117Z" fill="#24285B" />
            <path d="M8.51564 25.0117H4.85852V26.8214H8.51564V25.0117Z" fill="white" />
            <path d="M13.7318 25.0117H10.0747V26.8214H13.7318V25.0117Z" fill="white" />
            <path d="M18.974 25.0117H15.3169V26.8214H18.974V25.0117Z" fill="white" />
            <path d="M8.51564 28.7964H4.85852V30.606H8.51564V28.7964Z" fill="white" />
            <path d="M13.7318 28.7964H10.0747V30.606H13.7318V28.7964Z" fill="white" />
            <path d="M18.974 28.7964H15.3169V30.606H18.974V28.7964Z" fill="white" />
            <path d="M8.51564 32.5691H4.85852V34.3787H8.51564V32.5691Z" fill="white" />
            <path d="M13.7318 32.5691H10.0747V34.3787H13.7318V32.5691Z" fill="white" />
            <path d="M18.974 32.5691H15.3169V34.3787H18.974V32.5691Z" fill="white" />
            <path d="M8.51564 36.3053H4.85852V38.1149H8.51564V36.3053Z" fill="#FF7700" />
            <path d="M13.7318 36.3053H10.0747V38.1149H13.7318V36.3053Z" fill="#C1C1C1" />
            <path d="M18.974 36.3053H15.3169V38.1149H18.974V36.3053Z" fill="#FF7700" />
            <path d="M22.2805 37.4116C22.2273 37.4116 22.1764 37.3904 22.1388 37.3527C22.1012 37.315 22.0801 37.2639 22.0801 37.2106L22.6812 12.1173C22.6812 12.064 22.7024 12.0128 22.7399 11.9751C22.7775 11.9374 22.8285 11.9162 22.8816 11.9162C22.9348 11.9162 22.9858 11.9374 23.0233 11.9751C23.0609 12.0128 23.082 12.064 23.082 12.1173L22.4809 37.2106C22.4809 37.2639 22.4597 37.315 22.4222 37.3527C22.3846 37.3904 22.3336 37.4116 22.2805 37.4116Z" fill="#FF7700" />
            <path d="M17.9866 10.3462H6.21167V10.9775H17.9866V10.3462Z" fill="#C1C1C1" />
            <path d="M7.40588 10.9763V0.197046L8.61223 0.932955L9.78451 0L10.8967 0.940997L12.3114 0L13.4356 1.03751L14.5558 0L15.4996 1.00936L16.7801 0.197046V10.9763H7.40588Z" fill="#FF7700" />
            <path opacity="0.09" d="M19.2282 11.6142L14.8197 8.84747L9.30896 18.9109L24.2901 28.4998L24.7069 20.433L19.2282 11.6142Z" fill="black" />
            <path d="M36.0112 16.3057L19.2089 6.57196L12.9417 17.4638L29.7441 27.1975L36.0112 16.3057Z" fill="#FFD200" />
            <path d="M33.6982 17.2783L26.1406 12.9201L25.1056 14.7271L32.6632 19.0853L33.6982 17.2783Z" fill="white" />
            <path opacity="0.46" d="M20.0159 16.5831L17.326 15.0248C16.9599 14.8127 16.4917 14.9386 16.2803 15.306L16.0108 15.7744C15.7994 16.1418 15.9249 16.6115 16.291 16.8236L18.9809 18.3819C19.347 18.594 19.8152 18.4681 20.0266 18.1007L20.2961 17.6323C20.5075 17.265 20.3821 16.7952 20.0159 16.5831Z" fill="white" />
            <path opacity="0.46" d="M29.8437 22.2766L22.3449 17.9326C21.9788 17.7205 21.5106 17.8463 21.2993 18.2137L21.0297 18.6821C20.8184 19.0495 20.9438 19.5192 21.3099 19.7313L28.8087 24.0754C29.1748 24.2875 29.643 24.1616 29.8544 23.7943L30.1239 23.3258C30.3353 22.9585 30.2098 22.4887 29.8437 22.2766Z" fill="white" />
            <path d="M29.6163 31.5129C29.6163 31.5129 25.9432 27.948 26.1716 24.1056C26.2758 22.3402 28.5422 21.6586 29.5782 23.0902L29.6183 23.1485C30.9689 25.0727 30.8427 27.0753 33.7964 26.2751C36.7502 25.4748 33.3115 21.0011 33.3115 21.0011L36.0047 16.2981C36.0047 16.2981 39.3051 20.8945 40.1368 24.8435C40.9684 28.7925 40.1368 32.9606 40.1368 32.9606L32.6061 34.541L29.6163 31.5129Z" fill="#F4A28C" />
            <path d="M30.5406 33.4319L40.3758 31.3167L44.113 43.994L31.9955 45.8056L30.5406 33.4319Z" fill="#FF7700" />
            <path opacity="0.3" d="M20.5991 12.4311C21.3561 12.4311 21.9697 11.8153 21.9697 11.0558C21.9697 10.2962 21.3561 9.68045 20.5991 9.68045C19.8421 9.68045 19.2284 10.2962 19.2284 11.0558C19.2284 11.8153 19.8421 12.4311 20.5991 12.4311Z" fill="white" />
            <path opacity="0.3" d="M22.2621 13.2879C23.0192 13.2879 23.6328 12.6721 23.6328 11.9126C23.6328 11.153 23.0192 10.5373 22.2621 10.5373C21.5051 10.5373 20.8915 11.153 20.8915 11.9126C20.8915 12.6721 21.5051 13.2879 22.2621 13.2879Z" fill="white" />
        </svg>
    )
}

export default KpiPurchaseIntent
