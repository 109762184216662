import { withProgram } from '../../../contexts/ProgramContext'
import UsersListing from './Users/Listing'
import Kpis from './Kpi/Kpis'
import Filters from './Filters/Filters'
import { useState } from 'react'

function GlobalAudience() {
    const [filtersQueryParams, setFiltersQueryParams] = useState('')

    return (
        <>
            <Kpis />
            <div className="xl:flex flex-row mt-6 gap-6">
                <div className="h-full xl:w-1/4 w-full min-w-min mb-6">
                    <Filters onChange={setFiltersQueryParams} />
                </div>
                <div className="h-full xl:w-3/4 w-full">
                    <UsersListing filtersQueryParams={filtersQueryParams} />
                </div>
            </div>
        </>
    )
}

export default withProgram(GlobalAudience)
