type InProgramIconProps = {
    className?: string
}

function InProgramIcon({ className }: InProgramIconProps) {
    return (
        <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M5.35549 2.80859L4.00783 0.5625C3.91017 0.386719 3.6758 0.25 3.48049 0.25H1.31252C1.05861 0.25 0.902362 0.542969 1.03908 0.757812L3.22658 3.86328C3.79299 3.31641 4.53517 2.94531 5.35549 2.80859ZM10.668 0.25H8.50002C8.28517 0.25 8.08986 0.367188 7.97267 0.5625L6.62502 2.80859C7.44533 2.94531 8.18752 3.31641 8.75392 3.86328L10.9414 0.757812C11.0781 0.542969 10.9219 0.25 10.668 0.25ZM6.00002 3.375C4.08596 3.375 2.56252 4.91797 2.56252 6.8125C2.56252 8.72656 4.08596 10.25 6.00002 10.25C7.89455 10.25 9.43752 8.72656 9.43752 6.8125C9.43752 4.91797 7.89455 3.375 6.00002 3.375ZM7.79689 6.46094L7.05471 7.18359L7.23049 8.19922C7.26955 8.375 7.07424 8.51172 6.89846 8.43359L6.00002 7.94531L5.08205 8.43359C4.90627 8.51172 4.71096 8.375 4.75002 8.19922L4.9258 7.18359L4.18361 6.46094C4.04689 6.32422 4.12502 6.10938 4.3008 6.07031L5.33596 5.93359L5.78517 4.99609C5.82424 4.91797 5.90236 4.87891 5.98049 4.87891C6.07814 4.87891 6.15627 4.91797 6.19533 4.99609L6.64455 5.93359L7.67971 6.07031C7.85549 6.10938 7.93361 6.32422 7.79689 6.46094Z" fill="currentColor"/>
        </svg>
    )
}

export default InProgramIcon
