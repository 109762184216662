type KpiStoreWithPurchaseProps = {
    className?: string
}

function KpiStoreWithPurchase({ className }: KpiStoreWithPurchaseProps) {
    return (
        <svg viewBox="0 0 52 43" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M40.2145 7.76877H0V38.7332H40.2145V7.76877Z" fill="#E6E6E6"/>
            <path d="M37.2148 10.0786H2.99976V36.4239H37.2148V10.0786Z" fill="#C9C9C9"/>
            <path d="M29.5664 23.6695V36.4237" stroke="#E6E6E6" stroke-width="2.8372" stroke-miterlimit="10"/>
            <path opacity="0.1" d="M27.0588 7.76877H37.8987V8.01656L32.4776 17.2339H29.7331L27.0588 7.76877Z" fill="black"/>
            <path d="M12.4744 19.034L20.9584 26.2223" stroke="#E6E6E6" stroke-width="2.8372" stroke-miterlimit="10"/>
            <path d="M29.5664 23.6695L37.8983 28.997" stroke="#E6E6E6" stroke-width="2.8372" stroke-miterlimit="10"/>
            <path d="M18.2866 36.5079V29.6118L22.9701 23.6695H29.5644V15.1979" stroke="white" stroke-width="2.8372" stroke-miterlimit="10"/>
            <path d="M29.5669 18.5594C31.825 18.5594 33.6556 17.966 33.6556 17.234C33.6556 16.5019 31.825 15.9085 29.5669 15.9085C27.3088 15.9085 25.4783 16.5019 25.4783 17.234C25.4783 17.966 27.3088 18.5594 29.5669 18.5594Z" fill="#24285B"/>
            <path d="M35.5709 5.99685C35.5711 5.07103 35.3564 4.15776 34.9438 3.3286C34.5312 2.49944 33.9319 1.77692 33.1928 1.21765C32.4537 0.658383 31.5949 0.277564 30.6837 0.105034C29.7725 -0.0674951 28.8337 -0.0270479 27.9408 0.223207C27.0479 0.473461 26.2252 0.926725 25.5371 1.54748C24.849 2.16823 24.3143 2.93961 23.9748 3.80117C23.6353 4.66274 23.5002 5.59107 23.5803 6.51344C23.6603 7.43581 23.9532 8.32715 24.436 9.11761V9.11761L29.5667 17.5074L34.6694 9.15735C35.2601 8.20878 35.5725 7.1138 35.5709 5.99685V5.99685ZM29.5667 9.17839C28.986 9.17839 28.4182 9.00647 27.9353 8.68436C27.4524 8.36225 27.076 7.90443 26.8537 7.36878C26.6315 6.83314 26.5733 6.24373 26.6866 5.67509C26.7999 5.10645 27.0796 4.58413 27.4903 4.17416C27.901 3.7642 28.4242 3.48501 28.9939 3.3719C29.5635 3.25879 30.1539 3.31684 30.6905 3.53871C31.2271 3.76058 31.6857 4.13631 32.0084 4.61838C32.331 5.10045 32.5033 5.6672 32.5033 6.24698C32.5033 6.63194 32.4273 7.01313 32.2797 7.36878C32.1322 7.72444 31.9159 8.0476 31.6432 8.3198C31.3705 8.59201 31.0468 8.80793 30.6905 8.95525C30.3342 9.10257 29.9524 9.17839 29.5667 9.17839V9.17839Z" fill="#FF7700"/>
            <path d="M2.29736 19.0338H12.4745V9.27408" stroke="#E6E6E6" stroke-width="2.8372" stroke-miterlimit="10"/>
            <path opacity="0.1" d="M46.5255 31.2294H31.7117V38.7333H46.5255V31.2294Z" fill="black"/>
            <path d="M12.4744 14.7676H22.0918V9.27408" stroke="#E6E6E6" stroke-width="2.8372" stroke-miterlimit="10"/>
            <path d="M51.8531 31.2294H33.6555V42.3332H51.8531V31.2294Z" fill="#E6E6E6"/>
            <path d="M37.7301 37.7936C37.7301 37.7936 37.4444 39.3435 38.6925 39.1331C39.9407 38.9227 39.3459 38.2635 39.1609 37.4126L37.7301 37.7936Z" fill="#A5A5A5"/>
            <path d="M37.5824 37.0757L39.0249 36.7438C39.0249 36.7438 39.0834 35.5913 39.0249 34.4365C38.9663 33.2817 37.8189 32.3326 37.0602 33.1064C36.3014 33.8802 36.5801 35.4627 37.5824 37.0757Z" fill="#A5A5A5"/>
            <path d="M40.313 39.2244C40.313 39.2244 39.4911 40.3769 40.5846 40.6784C41.6782 40.98 41.4464 40.2109 41.6244 39.4628L40.313 39.2244Z" fill="#A5A5A5"/>
            <path d="M40.465 38.5792L41.7623 38.8573C41.7623 38.8573 42.2494 37.9433 42.6311 36.9872C43.0128 36.0311 42.4531 34.8179 41.5399 35.1638C40.6266 35.5098 40.2707 36.8867 40.465 38.5792Z" fill="#A5A5A5"/>
            <path opacity="0.67" d="M49.6354 35.283H45.2446V36.5009H49.6354V35.283Z" fill="#C9C9C9"/>
            <path opacity="0.31" d="M49.6354 37.4523H45.2446V38.6702H49.6354V37.4523Z" fill="#C9C9C9"/>
        </svg>
    )
}

export default KpiStoreWithPurchase
