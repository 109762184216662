import './AddressesInput.css'
import GooglePlacesAutocomplete, { PlaceResult } from './GooglePlacesAutocomplete'

type Place = {
    street_number: string
    street_name: string
    city: string
    postal_code: string
    country: string
    placeId: string
    formatted_address: string
}

type AddressesInputProps = {
    placeholder: string
    onChange: (data: Place) => void
    prefixSearch: string
}

function AddressesInput({ onChange, placeholder = '', prefixSearch = '' }: AddressesInputProps) {
    const getAddressComponent = (place: PlaceResult | null, key: string, longName = true) => {
        const nameType = longName ? 'long_name' : 'short_name'

        const value = place?.address_components?.filter((e) => e.types.includes(key))

        return value && value.length > 0 ? value[0][nameType] : ''
    }

    const formatData = (place: PlaceResult | null): Place => ({
        street_number: getAddressComponent(place, 'street_number'),
        street_name: getAddressComponent(place, 'route'),
        city: getAddressComponent(place, 'locality') || getAddressComponent(place, 'postal_town'),
        postal_code: getAddressComponent(place, 'postal_code'),
        country: getAddressComponent(place, 'country', false),
        placeId: Array.isArray(place?.types) && place?.types.includes('establishment') ? place.place_id || '' : '',
        formatted_address: place?.formatted_address || '',
    })

    return (
        <GooglePlacesAutocomplete
            prefixSearchValue={prefixSearch}
            placeholder={placeholder}
            showCreateOption={false}
            apiKey={process.env.REACT_APP_GOOGLE_PLACES_API}
            onAddressSelected={(place) => {
                onChange(formatData(place))
            }}
        />
    )
}

export default AddressesInput
