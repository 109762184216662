import Button, { ButtonAppearance } from './Button'
import Modal from './Modal'
import { useTranslation } from 'react-i18next'

const fallBackTranslationCode = 'errors.common'

export type ErrorState = {
    key: string
    code?: string
}

type ErrorMessageProps = {
    translationKey?: string
    errorCode?: string
    show: boolean
    onClose: () => any
}

enum EMessageElement {
    TITLE = 'title',
    DESCRIPTION = 'description',
    BUTTON = 'button',
}

function ErrorMessage({ translationKey, show, onClose, errorCode = '500' }: ErrorMessageProps) {
    const { t } = useTranslation('common')

    if (!translationKey) return null

    const getTranslation = (type: EMessageElement) => {
        const translationKeys = [
            `${translationKey}.${type}.${errorCode}.label`,
            `${translationKey}.${type}.label`,
            `${fallBackTranslationCode}.${type}.${errorCode}.label`,
            `${fallBackTranslationCode}.${type}.label`,
        ]

        for (const key of translationKeys) {
            const translated = t(key)
            if (translated !== key) {
                return translated
            }
        }
    }

    const title = getTranslation(EMessageElement.TITLE)
    const description = getTranslation(EMessageElement.DESCRIPTION)
    const button = getTranslation(EMessageElement.BUTTON)

    return (
        <Modal show={show} onClose={onClose}>
            <div data-testid="error_modal" className="flex flex-col gap-8 items-center max-w-lg p-4 text-center mx-12">
                <h3 className="bold">
                    <strong>{title}</strong>
                </h3>
                {!!description && <p>{description}</p>}
                <Button
                    appearance={ButtonAppearance.BUTTON_APPEARANCE_PRIMARY}
                    className="flex-1 py-4"
                    onClick={onClose}
                >
                    {button}
                </Button>
            </div>
        </Modal>
    )
}

export default ErrorMessage
